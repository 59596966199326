import React from "react"
import {graphql} from "gatsby"
import SEO from "../components/seo";
import TitlePage from "../components/TitlePage";
// import {MDXRenderer} from "gatsby-plugin-mdx"

import * as S from '../components/Content/styled';

// The normal <a> tag is modified here (so that internal links use gatsby-link/LocalizedLink
// More info:
// https://www.gatsbyjs.com/docs/mdx/customizing-components/
const Post = ({data}) => {
    const post = data.markdownRemark;

    return (
        <>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                // image={post.frontmatter.image}
            />
            <TitlePage text={post.frontmatter.title}/>
            <S.Content>
                <div dangerouslySetInnerHTML={{__html: post.html}}></div>
            </S.Content>
        </>
    )
}

export default Post

export const query = graphql`
    query Post($locale: String!, $title: String!) {
        markdownRemark(frontmatter: {title: {eq: $title}},
            fields: {locale: {eq: $locale}}) {
            frontmatter {
                title
                date
            }
            html
        }
    }

`;
